import moment, { Moment } from 'moment';
import { ISelectOption } from 'components/common/fields/select/SelectTypes';
import {
  ICompaniesDetailResponse,
  IMayFile,
  IMayFiles,
  IMayString,
  ISettingsSystemAgreementDataItemResponse,
  ISettingsSystemContractsDataResponse,
} from 'types/response';
import { StaffPosition, StaffType } from 'helpers/staff';
import { addressesList, AddressType, IAddressType } from 'helpers/addresses';
import { ICompaniesAddArgs, ICompaniesAddHistoryArgs } from 'components/services/companies/companiesTypes';
import { fromPairs, toPairs } from 'ramda';
import {
  IContractsAddArgs,
  IContractsActivateArgs,
  IContractsInvoicesActivateArgs,
  IContractsInvoicesAddArgs,
  IContractsInvoicesPayArgs,
  IContractsRegisterArgs,
  IContractsPayArgs,
} from 'components/services/contracts/contractsTypes';
import {
  getInitOption,
  isCreatedOption,
  multiSelectSerializer,
  PrefixCreateLabel,
  removeCreatedPrefixFromLabel,
} from 'helpers/select';
import { IProductsAddArgs, IProductsAddPackageArgs } from 'components/services/products/productsTypes';
import { toNumber } from 'helpers/number';
import { companyStatuses } from 'helpers/companies';
import { exportConfigAliasAll } from 'components/features/fields/export/config/ExportConfigFields';
import { IExportGenerateArgs } from 'components/services/export/exportTypes';
import { stringToSnakeCase } from 'helpers/register';
import {
  ISettingsBillingCompanyCategoriesAddArgs,
  ISettingsBillingContributionsAddArgs,
  ISettingsGeoBranchesAddArgs,
  ISettingsGeoCitiesAddArgs,
  ISettingsGeoCountriesAddArgs,
  ISettingsGeoDistrictsAddArgs,
  ISettingsGeoRegionsAddArgs,
  ISettingsUsersGroupsAddArgs,
  ISettingsUsersUsersAddArgs,
  ISettingsUsersUsersEditPasswordArgs,
  ISettingsSystemAgreementEditArgs,
  ISettingsSystemContractsAddArgs,
  ISettingsSystemOwnershipsAddArgs,
  ISettingsSystemBusinessActivitiesAddArgs,
  ISettingsSystemEconomicActivitiesAddArgs,
  ISettingsSystemBanksAddArgs,
} from 'components/services/settings/settingsTypes';
import { IAuthFieldsValues } from 'components/features/fields/auth/AuthFieldsTypes';
import { IAuthLoginArgs, IAuthLoginConfirmArgs } from 'components/services/auth/authTypes';

const authSerializer = (values: IAuthFieldsValues): IAuthLoginArgs => {
  const {
    username,
    password,
  } = values;

  return {
    username,
    password,
  };
};
const authConfirmSerializer = (values: IAuthFieldsValues): IAuthLoginConfirmArgs => {
  const {
    username,
    password,
    smsCode,
  } = values;

  return {
    username,
    password,
    smsCode,
  };
};

const fileSerializer = (file: IMayFile | undefined): string | undefined | null => file?.id || null;
const filesSerializer = (files: IMayFiles | undefined): Array<string> | null => files
  ?.map((item) => item?.id) || [];

interface IStaffItemFromForm {
  name: string
  phone?: string
  email?: string
}
interface IStaffsFromForm {
  [key: string]: IStaffItemFromForm
}
interface IGetStaffByKey {
  staffType: StaffType
  position: StaffPosition
}
interface IStaffSerializerItem extends IGetStaffByKey {
  position: StaffPosition
  staffType: StaffType
}
type IStaffSerializerList = IStaffSerializerItem[];
const staffsForPayloadSerializer = (staffsFromForm: IStaffsFromForm): IStaffSerializerList => {
  const pairedList = toPairs(staffsFromForm) ?? [];

  return pairedList.map(([ key, item ]) => {
    const { name, phone, email } = item;

    const getStaffByKey = (): IGetStaffByKey => {
      switch (key) {
        case StaffType.HEAD: return { staffType: StaffType.HEAD, position: StaffPosition.HEAD };
        case StaffType.ACCOUNTANT: return { staffType: StaffType.ACCOUNTANT, position: StaffPosition.ACCOUNTANT };
        default: return { staffType: StaffType.PERSON_IN_CHARGER, position: StaffPosition.PERSON_IN_CHARGER };
      }
    };

    return {
      name,
      phone,
      email,
      staffType: getStaffByKey().staffType,
      position: getStaffByKey().position,
    };
  });
};

const staffsForInitSerializer = (staffs: ICompaniesDetailResponse['staffs'] | undefined): IStaffsFromForm | undefined => {
  // TODO Rou 14.06.2023 Fix any type?!
  const staffsArr: any = staffs?.map((item) => [
    item?.staffType,
    {
      name: item?.name,
      phone: item?.phone,
      email: item?.email,
    },
  ]);

  return Array.isArray(staffsArr) ? fromPairs(staffsArr) : undefined;
};

interface IAddressItemFromForm {
  district: ISelectOption
  houseNumber: string | number
  phone?: string | number
  postcode: string | number
  region: ISelectOption
  street: string
}
interface IGLNSItemFromForm {
  postcode?: string | number
  region?: ISelectOption
  district?: ISelectOption
  street?: string
  houseNumber?: string | number
}
interface IAddressFromForm {
  [key: string]: IAddressItemFromForm
}
interface IAddressSerializerItem {
  district: IAddressItemFromForm['district']['value']
  houseNumber: string | number
  phone?: string | number
  postcode: string | number
  region: IAddressItemFromForm['region']['value']
  street: string
  addressType: IAddressType
}
type IAddressSerializerList = IAddressSerializerItem[];
interface IGLNSSerializerItem {
  district?: ISelectOption['value']
  houseNumber?: string | number
  phone?: string | number
  postcode?: string | number
  region?: ISelectOption['value']
  street?: string
}
type IGLNSSerializerList = IGLNSSerializerItem[];

const getAddressTypeByKey = (type: string): IAddressType => {
  switch (type) {
    case AddressType.ACTUAL: return AddressType.ACTUAL;
    default: return AddressType.LEGAL;
  }
};

const addressesForPayloadSerializer = (addressesFromForm: IAddressFromForm, isIdenticalAddresses: boolean): IAddressSerializerList => {
  const pairedList = toPairs(addressesFromForm) ?? [];

  const firstAddressType = addressesList[0]?.type;
  // TODO Rou 15.06.2023 Fix any type?!
  const pairedArr: Array<any> = isIdenticalAddresses
    ? addressesList?.map(({ type }) => {
      const firstPairedAddress = pairedList?.find(([ key ]) => key === firstAddressType);
      return [ getAddressTypeByKey(type), firstPairedAddress?.[1] ];
    })
    : pairedList;

  return pairedArr.map(([ key, item ]) => {
    const {
      district,
      houseNumber,
      phone,
      postcode,
      region,
      street,
    } = item;

    return {
      district: district.value,
      houseNumber,
      phone,
      postcode,
      region: region.value,
      street,
      addressType: getAddressTypeByKey(key),
    };
  });
};

const glnsForPayloadSerializer = (glnsFromForm?: ICompaniesCRUDFieldsValues['glns']) => glnsFromForm?.map((item) => ({
  postcode: item?.postcode,
  region: item?.region?.value,
  district: item?.district?.value,
  street: item?.street,
  houseNumber: item?.houseNumber,
}));

const addressesForInitSerializer = (addresses: ICompaniesDetailResponse['addresses'] | undefined): IAddressFromForm | undefined => {
  // TODO Rou 14.06.2023 Fix any type?!
  const addressesArr: any = addresses?.map((item) => [
    item?.addressType,
    {
      postcode: item?.postcode,
      phone: item?.phone,
      region: getInitOption(item?.region?.name, item?.region?.id),
      district: getInitOption(item?.district?.name, item?.district?.id),
      street: item?.street,
      houseNumber: item?.houseNumber,
    },
  ]);

  return Array.isArray(addressesArr) ? fromPairs(addressesArr) : undefined;
};
const glnsForInitSerializer = (glns: ICompaniesDetailResponse['glns'] | undefined): IGLNSItemFromForm[] | undefined => (Array.isArray(glns)
  ? glns?.map((item) => ({
    postcode: item?.postcode,
    region: getInitOption(item?.region?.name, item?.region?.id),
    district: getInitOption(item?.district?.name, item?.district?.id),
    street: item?.street,
    houseNumber: item?.houseNumber,
  })) : undefined);

interface ICompaniesCRUDFieldsValues {
  name: string
  tin: string | number
  ownership: ISelectOption
  gcpKind: ISelectOption
  photo?: IMayFile
  staffs: {
    [key: string]: IStaffItemFromForm
  }
  hasAccessCabinet?: boolean
  isTrusted?: boolean
  addresses: {
    [key: string]: IAddressItemFromForm
  }
  email: string
  webSite?: string | null
  bank: ISelectOption
  checkingAccount?: string | null
  ecoActivity?: ISelectOption
  isIdenticalAddresses: boolean
  glns?: IGLNSItemFromForm[]
  enabledGrp?: boolean
}

const companiesCRUDSerializer = (values: ICompaniesCRUDFieldsValues): ICompaniesAddArgs => {
  const {
    name,
    tin,
    ownership,
    gcpKind,
    photo,
    hasAccessCabinet = false,
    isTrusted = false,
    staffs,
    addresses,
    email,
    webSite,
    bank,
    checkingAccount,
    ecoActivity,
    isIdenticalAddresses,
    glns,
    enabledGrp,
  } = values;

  return {
    name: name?.toUpperCase(),
    tin,
    ownership: ownership?.value,
    gcpKind: gcpKind?.value,
    photo: fileSerializer(photo),
    hasAccessCabinet,
    isTrusted,
    staffs: staffsForPayloadSerializer(staffs),
    addresses: addressesForPayloadSerializer(addresses, isIdenticalAddresses),
    email,
    webSite,
    bank: bank?.value,
    checkingAccount,
    ecoActivity: ecoActivity?.value,
    glns: glnsForPayloadSerializer(glns),
    disabledGrp: !enabledGrp,
  };
};

interface ICompaniesMergeFieldsValues {
  child: ISelectOption
}

const companiesMergeSerializer = (companyId: number, { child }: ICompaniesMergeFieldsValues) => ({
  id: companyId,
  child: child.value,
});

interface ICompaniesHistoryCRUDFieldsValues {
  text: string
}

const companiesHistoryCRUDSerializer = (companyId: number, { text }: ICompaniesHistoryCRUDFieldsValues): ICompaniesAddHistoryArgs => ({
  id: companyId,
  text,
});

// Contracts
interface IContractsCRUDFieldsValues {
  company: ISelectOption
  ownership?: ISelectOption
  businessType: ISelectOption
  paymentType: ISelectOption
  memberCci?: boolean
  memberCciExpireDate?: string | null
  recipient?: string | null
  phone?: string | number | null
  deliveryType: ISelectOption
}

const contractsCRUDSerializer = (values: IContractsCRUDFieldsValues): IContractsAddArgs => {
  const {
    company,
    businessType,
    ownership,
    paymentType,
    memberCci = false,
    memberCciExpireDate,
    recipient,
    phone,
    deliveryType,
  } = values;

  const isExistCompany = !isCreatedOption(company);
  const companyPayload = isExistCompany ? {
    company: company?.value,
  } : {
    companyName: removeCreatedPrefixFromLabel(company, PrefixCreateLabel.COMPANY_NEW),
    ownership: ownership?.value,
  };

  return {
    ...companyPayload,
    businessType: businessType.value,
    paymentType: paymentType.value,
    memberCci,
    memberCciExpireDate: memberCciExpireDate ? moment(memberCciExpireDate).format('YYYY-MM-DD') : undefined,
    recipient,
    phone,
    deliveryType: deliveryType.value,
  };
};

interface ICompaniesContractsCRUDFieldsValues {
  recipient?: string | null
  phone?: string | number | null
  deliveryType: ISelectOption
  paymentType: ISelectOption
  businessType: ISelectOption
}

const companiesContractsCRUDSerializer = (companyId: number, values: ICompaniesContractsCRUDFieldsValues): IContractsRegisterArgs => {
  const {
    recipient,
    phone,
    deliveryType,
    paymentType,
    businessType,
  } = values;

  return {
    company: companyId,
    recipient,
    phone,
    deliveryType: deliveryType?.value,
    paymentType: paymentType?.value,
    businessType: businessType?.value,
  };
};

interface IContractsInvoicesCRUDFieldsValues {
  invoiceDate: Moment | string
  admissionFee: number | string
  membershipFee?: number | string
  beginDate?: Moment | string
  endDate?: Moment | string
}

const contractsInvoicesCRUDSerializer = (contractId: number, values: IContractsInvoicesCRUDFieldsValues): IContractsInvoicesAddArgs => {
  const {
    invoiceDate,
    admissionFee,
    membershipFee,
    beginDate,
    endDate,
  } = values;

  return {
    contract: contractId,
    invoiceDate: moment(invoiceDate).format('YYYY-MM-DD'),
    admissionFee: admissionFee ? toNumber(admissionFee) : undefined,
    membershipFee: membershipFee ? toNumber(membershipFee) : undefined,
    beginDate: moment(beginDate).format('YYYY-MM-DD'),
    endDate: moment(endDate).format('YYYY-MM-DD'),
  };
};

interface IContractsActivateFieldsValues {
  beginDate: Moment | string
  endDate: Moment | string
  paidAmount: number
}

const contractsActivateSerializer = (
  invoiceId: number,
  values: IContractsActivateFieldsValues,
): IContractsActivateArgs => {
  const {
    beginDate,
    endDate,
    paidAmount,
  } = values;

  return {
    id: invoiceId,
    beginDate: beginDate ? moment(beginDate).format('YYYY-MM-DD') : undefined,
    endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
    paidAmount,
  };
};

interface IContractsInvoicesActivateFieldsValues {
  beginDate: Moment | string
  endDate: Moment | string
  paidAmount: number
}

const contractsInvoicesActivateSerializer = (
  invoiceId: number,
  values: IContractsInvoicesActivateFieldsValues,
): IContractsInvoicesActivateArgs => {
  const {
    beginDate,
    endDate,
    paidAmount,
  } = values;

  return {
    id: invoiceId,
    beginDate: beginDate ? moment(beginDate).format('YYYY-MM-DD') : undefined,
    endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
    paidAmount,
  };
};

interface IContractsPayFieldsValues {
  paidAmount: number
  tin?: number | string | null
}

const contractsPaySerializer = (
  invoiceId: number,
  values: IContractsPayFieldsValues,
): IContractsPayArgs => {
  const {
    paidAmount,
    tin,
  } = values;

  return {
    id: invoiceId,
    paidAmount,
    tin,
  };
};

interface IContractsInvoicesPayFieldsValues {
  paidAmount: number
  tin?: number | string | null
}

const contractsInvoicesPaySerializer = (
  invoiceId: number,
  values: IContractsInvoicesPayFieldsValues,
): IContractsInvoicesPayArgs => {
  const {
    paidAmount,
    tin,
  } = values;

  return {
    id: invoiceId,
    paidAmount,
    tin,
  };
};

// Products
interface IProductsPackageItem {
  package: string | null
  quantity: string | number | null
  measurement: ISelectOption
  eancodeType: ISelectOption
  eancode?: IMayString
}
interface IProductsCRUDFieldsValues {
  productType: ISelectOption
  description: string | null
  name: string
  tradeMark: string | null
  targetMarket: ISelectOption[] | undefined
  company: ISelectOption
  photo?: IMayFiles
  package: IProductsPackageItem['package']
  quantity: IProductsPackageItem['quantity']
  measurement: IProductsPackageItem['measurement']
  eancodeType: IProductsPackageItem['eancodeType']
  packages?: IProductsPackageItem[] | undefined
}

const productsPackagesSerializer = (packages: IProductsPackageItem[] | undefined): IProductsAddPackageArgs[] | undefined => packages
  ?.filter((item) => {
    // Проверяем на наличие заполненных полей, игнорируем encode
    const keys = Object.keys(item) as (keyof IProductsPackageItem)[];
    return keys.some((key) => key !== 'eancode' && item[key]);
  })
  ?.map(({
    package: packageType,
    quantity,
    measurement,
    eancodeType,
    eancode,
  }) => ({
    package: packageType,
    quantity,
    measurement: measurement?.value,
    eancodeType: eancodeType?.value,
    eancode,
  }));

const productsCRUDSerializer = (values: IProductsCRUDFieldsValues): IProductsAddArgs => {
  const {
    productType,
    description,
    name,
    tradeMark,
    targetMarket,
    company,
    photo,
    package: packageType,
    quantity,
    measurement,
    eancodeType,
    packages,
  } = values;

  const serializedPhoto = filesSerializer(photo);

  return {
    productType: productType.value,
    description,
    name,
    tradeMark,
    company: company.value,
    targetMarket: multiSelectSerializer(targetMarket),
    files: serializedPhoto,
    package: packageType,
    quantity,
    measurement: measurement.value,
    eancodeType: eancodeType.value,
    packages: productsPackagesSerializer(packages),
  };
};

// Export
interface IExportCRUDFieldsValues {
  status: Record<string, boolean>
  config: Record<string, Record<string, boolean>>
  format: ISelectOption<string>
}
const exportCRUDSerializer = (values: IExportCRUDFieldsValues): IExportGenerateArgs => {
  const { format } = values;

  const filterStatus = companyStatuses
    ?.filter(({ alias }) => values?.status?.[alias])
    ?.map(({ alias }) => alias)
    ?.join(',');

  const configValues = values?.config || {};
  const configValuesArr = toPairs(configValues);
  const configArr = configValuesArr
    ?.map(([ configAlias, fields = {} ]) => {
      const fieldsArr = toPairs(fields)
        ?.filter(([ fieldsAlias, bool ]) => fieldsAlias !== exportConfigAliasAll && bool)
        ?.map(([ fieldsAlias ]) => stringToSnakeCase(fieldsAlias));

      return [ configAlias, fieldsArr ];
    }) as [string, string[]][];
  const config = fromPairs(configArr) as Record<string, string[]>;

  return {
    format: format.value,
    filter: { status: filterStatus },
    config,
  };
};

// Settings
interface ISettingsUsersGroupsCRUDFieldsValues {
  name: string
  permissions: ISelectOption[]
}
const settingsUsersGroupsCRUDSerializer = (values: ISettingsUsersGroupsCRUDFieldsValues): ISettingsUsersGroupsAddArgs => {
  const {
    name,
    permissions,
  } = values;

  return {
    name,
    permissions: multiSelectSerializer(permissions),
  };
};
interface ISettingsUsersUsersCRUDFieldsValues {
  firstName: string
  lastName: string
  username: string
  branch: ISelectOption
  groups: ISelectOption[]
  permissions: ISelectOption[]
  phone: string | number | null
}
const settingsUsersUsersCRUDSerializer = (values: ISettingsUsersUsersCRUDFieldsValues): ISettingsUsersUsersAddArgs => {
  const {
    firstName,
    lastName,
    username,
    branch,
    groups,
    permissions,
    phone,
  } = values;

  return {
    firstName,
    lastName,
    username,
    branch: branch.value,
    groups: multiSelectSerializer(groups),
    permissions: multiSelectSerializer(permissions),
    phone,
  };
};
interface ISettingsUsersUsersPasswordSerializer {
  password: string
}
const settingsUsersUsersPasswordSerializer = (
  values: ISettingsUsersUsersPasswordSerializer,
): Omit<ISettingsUsersUsersEditPasswordArgs, 'id'> => {
  const { password } = values;

  return { password };
};
interface ISettingsBillingContributionsCRUDFieldsValues {
  activeDate?: Moment | string
  amount: number
  category: ISelectOption
  paymentType: ISelectOption
}
const settingsBillingContributionsCRUDSerializer = (
  values: ISettingsBillingContributionsCRUDFieldsValues,
): ISettingsBillingContributionsAddArgs => {
  const {
    activeDate,
    amount,
    category,
    paymentType,
  } = values;

  return {
    activeDate: activeDate ? moment(activeDate).format('YYYY-MM-DD') : undefined,
    amount,
    category: category?.value,
    paymentType: paymentType?.value,
  };
};
interface ISettingsBillingCompanyCategoriesCRUDFieldsValues {
  name: string
  letter: string
}
const settingsBillingCompanyCategoriesCRUDSerializer = (
  values: ISettingsBillingCompanyCategoriesCRUDFieldsValues,
): ISettingsBillingCompanyCategoriesAddArgs => {
  const { name, letter } = values;

  return { name, letter };
};
interface ISettingsGeoCountriesCRUDFieldsValues {
  name: string
  shortName: string
  order: number
}
const settingsGeoCountriesCRUDSerializer = (
  values: ISettingsGeoCountriesCRUDFieldsValues,
): ISettingsGeoCountriesAddArgs => {
  const { name, shortName, order } = values;

  return { name, shortName, order };
};
interface ISettingsGeoRegionsCRUDFieldsValues {
  name: string
  country: ISelectOption
}
const settingsGeoRegionsCRUDSerializer = (
  values: ISettingsGeoRegionsCRUDFieldsValues,
): ISettingsGeoRegionsAddArgs => {
  const { name, country } = values;

  return {
    name,
    country: country?.value,
  };
};
interface ISettingsGeoCitiesCRUDFieldsValues {
  name: string
  region: ISelectOption
  order?: number
}
const settingsGeoCitiesCRUDSerializer = (
  values: ISettingsGeoCitiesCRUDFieldsValues,
): ISettingsGeoCitiesAddArgs => {
  const { name, region, order } = values;

  return {
    name,
    region: region?.value,
    order,
  };
};
interface ISettingsGeoDistrictsCRUDFieldsValues {
  name: string
  region: ISelectOption
}
const settingsGeoDistrictsCRUDSerializer = (
  values: ISettingsGeoDistrictsCRUDFieldsValues,
): ISettingsGeoDistrictsAddArgs => {
  const { name, region } = values;

  return {
    name,
    region: region?.value,
  };
};
interface ISettingsGeoBranchesCRUDFieldsValues {
  name: string
  regions: ISelectOption[]
}
const settingsGeoBranchesCRUDSerializer = (
  values: ISettingsGeoBranchesCRUDFieldsValues,
): ISettingsGeoBranchesAddArgs => {
  const { name, regions } = values;

  return {
    name,
    regions: multiSelectSerializer(regions),
  };
};
interface ISettingsSystemAgreementCRUDFieldsValues {
  data: ISettingsSystemAgreementDataItemResponse[]
}
const settingsSystemAgreementCRUDSerializer = (
  values: ISettingsSystemAgreementCRUDFieldsValues,
): ISettingsSystemAgreementEditArgs => {
  const { data } = values;

  return { data };
};
interface ISettingsSystemContractsCRUDFieldsValues {
  category: ISelectOption
  data: ISettingsSystemContractsDataResponse[]
}
const settingsSystemContractsCRUDSerializer = (
  values: ISettingsSystemContractsCRUDFieldsValues,
): ISettingsSystemContractsAddArgs => {
  const { category, data } = values;

  return {
    category: category?.value,
    data,
  };
};
interface ISettingsSystemOwnershipsCRUDFieldsValues {
  name: string
}
const settingsSystemOwnershipsCRUDSerializer = (
  values: ISettingsSystemOwnershipsCRUDFieldsValues,
): ISettingsSystemOwnershipsAddArgs => {
  const { name } = values;

  return { name };
};
interface ISettingsSystemBusinessActivitiesCRUDFieldsValues {
  name: string
}
const settingsSystemBusinessActivitiesCRUDSerializer = (
  values: ISettingsSystemBusinessActivitiesCRUDFieldsValues,
): ISettingsSystemBusinessActivitiesAddArgs => {
  const { name } = values;

  return { name };
};
interface ISettingsSystemEconomicActivitiesCRUDFieldsValues {
  name: string
  section?: string
  group?: string
  klass?: string
  subKlass?: string
}
const settingsSystemEconomicActivitiesCRUDSerializer = (
  values: ISettingsSystemEconomicActivitiesCRUDFieldsValues,
): ISettingsSystemEconomicActivitiesAddArgs => {
  const {
    name,
    section,
    group,
    klass,
    subKlass,
  } = values;

  return {
    name,
    section,
    group,
    klass,
    subKlass,
  };
};
interface ISettingsSystemBanksCRUDFieldsValues {
  name: string
  code: string
}
const settingsSystemBanksCRUDSerializer = (
  values: ISettingsSystemBanksCRUDFieldsValues,
): ISettingsSystemBanksAddArgs => {
  const {
    name,
    code,
  } = values;

  return {
    name,
    code,
  };
};

export type {
  IStaffSerializerList,
  IAddressSerializerList,
  IGLNSSerializerList,
  ICompaniesCRUDFieldsValues,
  ICompaniesMergeFieldsValues,
  ICompaniesHistoryCRUDFieldsValues,
  IContractsCRUDFieldsValues,
  ICompaniesContractsCRUDFieldsValues,
  IContractsInvoicesCRUDFieldsValues,
  IContractsActivateFieldsValues,
  IContractsInvoicesActivateFieldsValues,
  IContractsPayFieldsValues,
  IContractsInvoicesPayFieldsValues,
  IProductsPackageItem,
  IProductsCRUDFieldsValues,
  IExportCRUDFieldsValues,
  ISettingsUsersGroupsCRUDFieldsValues,
  ISettingsUsersUsersCRUDFieldsValues,
  ISettingsUsersUsersPasswordSerializer,
  ISettingsBillingContributionsCRUDFieldsValues,
  ISettingsBillingCompanyCategoriesCRUDFieldsValues,
  ISettingsGeoCountriesCRUDFieldsValues,
  ISettingsGeoRegionsCRUDFieldsValues,
  ISettingsGeoCitiesCRUDFieldsValues,
  ISettingsGeoDistrictsCRUDFieldsValues,
  ISettingsGeoBranchesCRUDFieldsValues,
  ISettingsSystemAgreementCRUDFieldsValues,
  ISettingsSystemContractsCRUDFieldsValues,
  ISettingsSystemOwnershipsCRUDFieldsValues,
  ISettingsSystemBusinessActivitiesCRUDFieldsValues,
  ISettingsSystemEconomicActivitiesCRUDFieldsValues,
  ISettingsSystemBanksCRUDFieldsValues,
};

export {
  authSerializer,
  authConfirmSerializer,
  staffsForPayloadSerializer,
  staffsForInitSerializer,
  addressesForPayloadSerializer,
  addressesForInitSerializer,
  glnsForInitSerializer,
  companiesCRUDSerializer,
  companiesMergeSerializer,
  companiesHistoryCRUDSerializer,
  contractsCRUDSerializer,
  companiesContractsCRUDSerializer,
  contractsInvoicesCRUDSerializer,
  contractsActivateSerializer,
  contractsInvoicesActivateSerializer,
  contractsPaySerializer,
  contractsInvoicesPaySerializer,
  productsCRUDSerializer,
  exportCRUDSerializer,
  settingsUsersGroupsCRUDSerializer,
  settingsUsersUsersCRUDSerializer,
  settingsUsersUsersPasswordSerializer,
  settingsBillingContributionsCRUDSerializer,
  settingsBillingCompanyCategoriesCRUDSerializer,
  settingsGeoCountriesCRUDSerializer,
  settingsGeoRegionsCRUDSerializer,
  settingsGeoCitiesCRUDSerializer,
  settingsGeoDistrictsCRUDSerializer,
  settingsGeoBranchesCRUDSerializer,
  settingsSystemAgreementCRUDSerializer,
  settingsSystemContractsCRUDSerializer,
  settingsSystemOwnershipsCRUDSerializer,
  settingsSystemBusinessActivitiesCRUDSerializer,
  settingsSystemEconomicActivitiesCRUDSerializer,
  settingsSystemBanksCRUDSerializer,
};
