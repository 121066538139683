import useSelector from 'hooks/redux/useSelector';
import StateName from 'helpers/stateNames';
import { profileApi } from 'components/services/profile/profileService';
import { hasPermissions, IPermissionsEqArgs } from 'helpers/permissions';

interface IUsePermissionsProps {
  current?: IPermissionsEqArgs['current']
  compareBy?: IPermissionsEqArgs['compareBy']
}
interface IUsePermissions {
  has: boolean
}

function usePermissions(props: IUsePermissionsProps): IUsePermissions {
  const { current = [], compareBy } = props;

  const authState = useSelector((state) => state[StateName.AUTH]);
  const isAuth = authState.verify;

  const { useDetailQuery: useProfileQuery, usePermissionsQuery } = profileApi;
  const { data: profileData } = useProfileQuery(undefined, { skip: !isAuth });
  const { data: permissionsData } = usePermissionsQuery(undefined, { skip: !isAuth });

  const has = hasPermissions({
    current,
    all: permissionsData,
    profile: profileData?.permissions,
    compareBy,
    isSuperuser: profileData?.isSuperuser,
  });

  return {
    has,
  };
}

export default usePermissions;
