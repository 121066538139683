import { createApi } from '@reduxjs/toolkit/dist/query/react';
import StateName from 'helpers/stateNames';
import { RequestAPI } from 'helpers/requests';
import { axiosBaseQuery } from 'helpers/axios';
import {
  ICompaniesByStatusResponse,
  IFinancesIncomeResponse,
  INewCompaniesByYearsResponse,
  ICompaniesByBusinessTypesResponse,
} from 'types/response';
import { dataToSnakeCase } from 'helpers/register';
import { IFinancesIncomeArgs, INewCompaniesByYearsArgs } from './statisticsTypes';

const statisticsApi = createApi({
  reducerPath: StateName.STATISTICS_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    companiesByStatus: build.query<ICompaniesByStatusResponse, void>({
      query() {
        return {
          url: RequestAPI.STATISTICS_COMPANIES_BY_STATUS,
        };
      },
    }),
    financesIncome: build.query<IFinancesIncomeResponse, IFinancesIncomeArgs>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_FINANCES_INCOME,
          params: dataToSnakeCase(args),
        };
      },
    }),
    newCompaniesByYears: build.query<INewCompaniesByYearsResponse, INewCompaniesByYearsArgs>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_NEW_COMPANIES_BY_YEARS,
          params: dataToSnakeCase(args),
        };
      },
    }),
    companiesByBusinessTypes: build.query<ICompaniesByBusinessTypesResponse, void>({
      query(args) {
        return {
          url: RequestAPI.STATISTICS_COMPANIES_BY_BUSINESS_TYPES,
          params: dataToSnakeCase(args),
        };
      },
    }),
  }),
});

export {
  statisticsApi,
};
