import React, { ChangeEvent, useEffect } from 'react';
import { isNil } from 'ramda';
import { ITextFieldProps, TextFieldType, TextTheme } from './TextFieldTypes';
import {
  TextFieldCaption,
  TextFieldInput,
  TextFieldLabel,
  TextFieldPrefix,
  TextFieldWrap,
} from './TextFieldStyles';

function TextField(props: ITextFieldProps) {
  const {
    type: typeBase = TextFieldType.TEXT,
    name: nameBase,
    value: valueBase,
    textTheme: textThemeBase = TextTheme.PRIMARY,
    label,
    caption,
    required,
    input,
    defaultValue,
    placeholder,
    prefix: Prefix,
    onChange,
    onKeyDown,
    className,
    maxLength: maxLengthBase = 170,
    autoFocus = false,
    unmountClear = true,
    disabled = false,
    pattern: patternBase,
    autoComplete = true,
  } = props;

  useEffect(() => () => {
    if (unmountClear) input?.onChange(undefined);
  }, []);

  const type = input?.type || typeBase;
  const name = input?.name || nameBase;
  const value = input?.value || valueBase;

  const isTextArea = [ input?.type, type ].some((t) => t === TextFieldType.AREA);

  const postCodeMaxLength = 6;
  const bankRequisitesMaxLength = 20;
  const tinAltLength = 9;
  const tinMaxLength = 14;

  const emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  const postCodePattern = `^[0-9]{${postCodeMaxLength}}$`;
  const bankRequisitesPattern = `^[0-9]{${bankRequisitesMaxLength}}$`;
  const tinPattern = `^(?:\\d{${tinAltLength}}|\\d{${tinMaxLength}})$`;

  const pattern = (() => {
    switch (type) {
      case TextFieldType.EMAIL: return emailPattern;
      case TextFieldType.POST_CODE: return postCodePattern;
      case TextFieldType.BANK_REQUISITES: return bankRequisitesPattern;
      case TextFieldType.TIN: return tinPattern;
      default: return patternBase;
    }
  })();

  const inputType = (() => {
    switch (type) {
      case TextFieldType.TEXT:
      case TextFieldType.BANK_REQUISITES:
      case TextFieldType.TIN:
      case TextFieldType.POST_CODE: return TextFieldType.TEXT;
      default: return type;
    }
  })();

  const maxLength = (() => {
    switch (type) {
      case TextFieldType.POST_CODE: return postCodeMaxLength;
      case TextFieldType.BANK_REQUISITES: return bankRequisitesMaxLength;
      case TextFieldType.TIN: return tinMaxLength;
      default: return maxLengthBase;
    }
  })();

  const hasPrefix = !!Prefix;

  const emptyPlaceholder = ' ';

  const prefixEl = hasPrefix ? (
    <TextFieldPrefix>
      <Prefix />
    </TextFieldPrefix>
  ) : null;

  const hasLabel = !!label;

  const labelEl = hasLabel ? (
    <TextFieldLabel>
      {label}
      {required ? ' *' : null}
    </TextFieldLabel>
  ) : null;

  const textTheme = (() => {
    if (isTextArea) return TextTheme.AREA;
    if (textThemeBase === TextTheme.AREA && !isTextArea) return TextTheme.PRIMARY;
    return textThemeBase;
  })();

  const inputEl = (
    <TextFieldInput
      type={isTextArea ? undefined : inputType}
      name={name}
      value={value}
      textTheme={textTheme}
      placeholder={label ? emptyPlaceholder : placeholder}
      required={required}
      hasPrefix={hasPrefix}
      hasLabel={hasLabel}
      pattern={pattern}
      autoComplete={autoComplete ? 'on' : 'off'}
      maxLength={maxLength}
      defaultValue={defaultValue ?? input?.defaultValue}
      as={isTextArea ? 'textarea' : 'input'}
      // TODO Добавить удаление не цифр в определённых типах?!
      onChange={(ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!isNil(onChange)) onChange(ev);
        input?.onChange(ev);
      }}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus}
      disabled={disabled}
      {...input}
    />
  );

  const captionEl = caption ? (
    <TextFieldCaption>{caption}</TextFieldCaption>
  ) : null;

  return (
    <TextFieldWrap className={className}>
      {inputEl}
      {labelEl}
      {prefixEl}
      {captionEl}
    </TextFieldWrap>
  );
}

export default TextField;
