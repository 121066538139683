import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { createSlice } from '@reduxjs/toolkit';
import StateName from 'helpers/stateNames';
import { axiosBaseQuery } from 'helpers/axios';
import { RequestAPI } from 'helpers/requests';
import Cookies from 'js-cookie';
import { Cookie } from 'helpers/cookies';
import { IAuthState, IAuthLoginArgs, IAuthLoginConfirmArgs } from 'components/services/auth/authTypes';
import { IAuthLoginConfirmResponse, IAuthLoginResponse } from 'types/response';
import { dataToSnakeCase } from 'helpers/register';
import { stringToBoolean } from 'helpers/boolean';
import { profileApi } from 'components/services/profile/profileService';

const initialState: IAuthState = {
  verify: stringToBoolean(Cookies.get(Cookie.VERIFY)),
};

const authApi = createApi({
  reducerPath: StateName.AUTH_API,
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    login: build.mutation<IAuthLoginResponse, IAuthLoginArgs>({
      query(data) {
        return {
          url: RequestAPI.AUTH,
          method: 'post',
          data: dataToSnakeCase(data),
        };
      },
    }),
    loginConfirm: build.mutation<IAuthLoginConfirmResponse, IAuthLoginConfirmArgs>({
      query(data) {
        return {
          url: RequestAPI.AUTH_CONFIRM,
          method: 'post',
          data: dataToSnakeCase(data),
        };
      },
    }),
    logout: build.mutation<void, void>({
      query() {
        return {
          url: RequestAPI.AUTH_LOGOUT,
          method: 'post',
        };
      },
    }),
  }),
});

const setVerifyReducer = (state: IAuthState, verify: boolean) => {
  if (verify) {
    Cookies.set(Cookie.VERIFY, `${verify}`);
  } else {
    Cookies.remove(Cookie.VERIFY);
  }
  state.verify = verify;
};

const authSlice = createSlice({
  name: StateName.AUTH,
  initialState,
  reducers: {
    // TODO Rou 22/06/24 Нужно ли?! Можно ли избежать?!
    logout: (state: IAuthState) => {
      setVerifyReducer(state, false);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.loginConfirm.matchFulfilled,
        (state, action) => {
          setVerifyReducer(state, action.payload.auth);
        },
      )
      .addMatcher(
        profileApi.endpoints.detail.matchFulfilled,
        (state, action) => {
          // TODO Rou 14/07/24 Быстрое решение на logout, если isStaff false?!
          setVerifyReducer(state, action.payload.isStaff);
        },
      )
      .addMatcher(
        authApi.endpoints.logout.matchFulfilled,
        (state) => {
          setVerifyReducer(state, false);
        },
      );
  },
});

export {
  authApi,
  authSlice,
};
