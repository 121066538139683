import { IRoutePath } from 'helpers/routePath';
import { FunctionComponent, SVGProps } from 'react';
import { IPermissionsEqArgs } from '../../../helpers/permissions';

const SidebarType = {
  FULL: 'full',
  COMPACT: 'compact',
  HIDE: 'hide',
} as const;
type ISidebarType = typeof SidebarType[keyof typeof SidebarType];
interface ISidebarProps {
  type?: ISidebarType
}
type IIsShort = boolean;
interface ISidebarWrap {
  isShort: IIsShort
  isHide: boolean
}
interface ISidebarCompressSC {
  isCompressed: boolean
}
interface ISidebarMenuBase {
  isShort: IIsShort
}
interface ISidebarMenuItemBasic {
  label: string,
  path: IRoutePath | string
  count?: number
  permissions?: {
    current: IPermissionsEqArgs['current']
    compareBy?: IPermissionsEqArgs['compareBy']
  }
}
interface ISidebarMenuItem extends ISidebarMenuItemBasic {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  children?: ISidebarMenuItemBasic[]
}
type ISidebarMenu = ISidebarMenuItem[];

export {
  SidebarType,
};

export type {
  ISidebarType,
  ISidebarProps,
  ISidebarWrap,
  ISidebarCompressSC,
  ISidebarMenuBase,
  ISidebarMenu,
  ISidebarMenuItem,
  ISidebarMenuItemBasic,
};
