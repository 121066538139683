const SearchQuery = {
  REDIRECT: 'redirect',
  PAGE: 'page',
  SIDEBAR_PAGE: 'sidebarPage',
  CONTRACTS_PAGE: 'contractsPage',
  INVOICES_PAGE: 'invoicesPage',
  PRODUCTS_PAGE: 'productsPage',
  HISTORY_PAGE: 'historyPage',
  PAGE_SIZE: 'pageSize',
  CONTRACTS_PAGE_SIZE: 'contractsPageSize',
  INVOICES_PAGE_SIZE: 'invoicesPageSize',
  PRODUCTS_PAGE_SIZE: 'productsPageSize',
  HISTORY_PAGE_SIZE: 'historyPageSize',
  SEARCH: 'search',
  GLOBAL_SEARCH: 'globalSearch',
  PRODUCTS_SEARCH: 'productsSearch',
  CONTRACTS_SEARCH: 'contractsSearch',
  SIDEBAR_SEARCH: 'sidebarSearch',
  OWNERSHIPS: 'ownerships',
  CONTRACTS_OWNERSHIPS: 'contractsOwnerships',
  REGION: 'region',
  BRANCHES: 'branches',
  CONTRACTS_BRANCHES: 'contractsBranches',
  PAYMENT_TYPE: 'paymentType',
  CONTRACTS_PAYMENT_TYPE: 'contractsPaymentType',
  CREATED_DATE: 'createdDate',
  CREATED_MONTH: 'createdMonth',
  CONTRACTS_CREATED_MONTH: 'contractsCreatedMonth',
  CREATED_YEAR: 'createdYear',
  CONTRACTS_CREATED_YEAR: 'contractsCreatedYear',
  PRINTED: 'printed',
  PRODUCTS_PRINTED: 'productsPrinted',
  PRODUCTS_GCP: 'productsGCP',
  PRODUCTS_STATUS: 'productsStatus',
  ENCODING: 'encoding',
} as const;
type ISearchQuery = typeof SearchQuery[keyof typeof SearchQuery];

export type {
  ISearchQuery,
};
export default SearchQuery;
