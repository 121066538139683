import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { vsprintf } from 'sprintf-js';
import StateName from 'helpers/stateNames';
import { axiosBaseQuery } from 'helpers/axios';
import { RequestAPI } from 'helpers/requests';
import { dataToSnakeCase } from 'helpers/register';
import {
  ISettingsUsersGroupsListArgs,
  ISettingsUsersGroupsDetailArgs,
  ISettingsUsersGroupsAddArgs,
  ISettingsUsersGroupsEditArgs,
  ISettingsUsersGroupsRemoveArgs,
  ISettingsUsersUsersListArgs,
  ISettingsUsersUsersDetailArgs,
  ISettingsUsersUsersAddArgs,
  ISettingsUsersUsersEditArgs,
  ISettingsUsersUsersRemoveArgs,
  ISettingsUsersUsersEditPasswordArgs,
  ISettingsBillingContributionsListArgs,
  ISettingsBillingContributionsRemoveArgs,
  ISettingsBillingContributionsDetailArgs,
  ISettingsBillingContributionsAddArgs,
  ISettingsBillingContributionsEditArgs,
  ISettingsBillingCompanyCategoriesListArgs,
  ISettingsBillingCompanyCategoriesDetailArgs,
  ISettingsBillingCompanyCategoriesAddArgs,
  ISettingsBillingCompanyCategoriesEditArgs,
  ISettingsBillingCompanyCategoriesRemoveArgs,
  ISettingsGeoCountriesListArgs,
  ISettingsGeoCountriesDetailArgs,
  ISettingsGeoCountriesAddArgs,
  ISettingsGeoCountriesEditArgs,
  ISettingsGeoCountriesRemoveArgs,
  ISettingsGeoRegionsListArgs,
  ISettingsGeoRegionsDetailArgs,
  ISettingsGeoRegionsAddArgs,
  ISettingsGeoRegionsEditArgs,
  ISettingsGeoRegionsRemoveArgs,
  ISettingsGeoCitiesListArgs,
  ISettingsGeoCitiesDetailArgs,
  ISettingsGeoCitiesAddArgs,
  ISettingsGeoCitiesEditArgs,
  ISettingsGeoCitiesRemoveArgs,
  ISettingsGeoDistrictsListArgs,
  ISettingsGeoDistrictsDetailArgs,
  ISettingsGeoDistrictsAddArgs,
  ISettingsGeoDistrictsEditArgs,
  ISettingsGeoDistrictsRemoveArgs,
  ISettingsGeoBranchesListArgs,
  ISettingsGeoBranchesDetailArgs,
  ISettingsGeoBranchesAddArgs,
  ISettingsGeoBranchesEditArgs,
  ISettingsGeoBranchesRemoveArgs,
  ISettingsSystemAgreementEditArgs,
  ISettingsSystemContractsListArgs,
  ISettingsSystemContractsDetailArgs,
  ISettingsSystemContractsAddArgs,
  ISettingsSystemContractsEditArgs,
  ISettingsSystemContractsRemoveArgs,
  ISettingsSystemOwnershipsListArgs,
  ISettingsSystemOwnershipsDetailArgs,
  ISettingsSystemOwnershipsAddArgs,
  ISettingsSystemOwnershipsEditArgs,
  ISettingsSystemOwnershipsRemoveArgs,
  ISettingsSystemBusinessActivitiesListArgs,
  ISettingsSystemBusinessActivitiesDetailArgs,
  ISettingsSystemBusinessActivitiesAddArgs,
  ISettingsSystemBusinessActivitiesEditArgs,
  ISettingsSystemBusinessActivitiesRemoveArgs,
  ISettingsSystemEconomicActivitiesListArgs,
  ISettingsSystemEconomicActivitiesDetailArgs,
  ISettingsSystemEconomicActivitiesAddArgs,
  ISettingsSystemEconomicActivitiesEditArgs,
  ISettingsSystemEconomicActivitiesRemoveArgs,
  ISettingsSystemProductTypesListArgs,
  ISettingsSystemProductTypesUploadExcelArgs,
  ISettingsSystemBanksListArgs,
  ISettingsSystemBanksDetailArgs,
  ISettingsSystemBanksAddArgs,
  ISettingsSystemBanksEditArgs,
  ISettingsSystemBanksRemoveArgs,
} from 'components/services/settings/settingsTypes';
import {
  ISettingsUsersGroupsResponse,
  ISettingsUsersGroupsDetailResponse,
  ISettingsUsersUsersResponse,
  IUserResponse,
  ISettingsBillingContributionsResponse,
  ISettingsBillingContributionsItemResponse,
  ISettingsBillingCompanyCategoriesResponse,
  ISettingsBillingCompanyCategoriesItemResponse,
  ISettingsGeoCountriesResponse,
  ISettingsGeoCountriesItemResponse,
  ISettingsGeoRegionsResponse,
  ISettingsGeoRegionsItemResponse,
  ISettingsGeoCitiesResponse,
  ISettingsGeoCitiesItemResponse,
  ISettingsGeoDistrictsResponse,
  ISettingsGeoDistrictsItemResponse,
  ISettingsGeoBranchesItemResponse,
  ISettingsGeoBranchesResponse,
  ISettingsSystemAgreementResponse,
  ISettingsSystemContractsDetailResponse,
  ISettingsSystemContractsResponse,
  ISettingsSystemOwnershipsResponse,
  ISettingsSystemOwnershipsItemResponse,
  ISettingsSystemBanksResponse,
  ISettingsSystemBanksItemResponse,
  ISettingsSystemBusinessActivitiesResponse,
  ISettingsSystemBusinessActivitiesItemResponse,
  ISettingsSystemEconomicActivitiesResponse,
  ISettingsSystemEconomicActivitiesItemResponse,
  ISettingsSystemProductTypesResponse,
} from 'types/response';

const settingsApi = createApi({
  reducerPath: StateName.SETTINGS_API,
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'UsersGroupsList',
    'UsersGroupsDetail',
    'UsersUsersList',
    'UsersUsersDetail',
    'GeoBranchesList',
    'GeoBranchesDetail',
    'BillingContributionsList',
    'BillingContributionsDetail',
    'BillingCompanyCategoriesList',
    'BillingCompanyCategoriesDetail',
    'GeoCountriesList',
    'GeoCountriesDetail',
    'GeoRegionsList',
    'GeoRegionsDetail',
    'GeoCitiesList',
    'GeoCitiesDetail',
    'GeoDistrictsList',
    'GeoDistrictsDetail',
    'SystemAgreementDetail',
    'SystemContractsList',
    'SystemContractsDetail',
    'SystemOwnershipsList',
    'SystemOwnershipsDetail',
    'SystemBusinessActivitiesList',
    'SystemBusinessActivitiesDetail',
    'SystemEconomicActivitiesList',
    'SystemEconomicActivitiesDetail',
    'SystemBanksList',
    'SystemBanksDetail',
  ],
  endpoints: (build) => ({
    usersGroupsList: build.query<ISettingsUsersGroupsResponse, ISettingsUsersGroupsListArgs>({
      query(args) {
        return {
          url: RequestAPI.USERS_GROUPS,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'UsersGroupsList' ],
    }),
    usersGroupsListAll: build.query<ISettingsUsersGroupsResponse, void>({
      query() {
        return {
          url: RequestAPI.USERS_GROUPS,
          params: dataToSnakeCase({ page: 1, pageSize: 1000 }),
        };
      },
      providesTags: [ 'UsersGroupsList' ],
    }),
    usersGroupsDetail: build.query<ISettingsUsersGroupsDetailResponse, ISettingsUsersGroupsDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.USERS_GROUPS_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'UsersGroupsDetail' ],
    }),
    usersGroupsAdd: build.mutation<ISettingsUsersGroupsDetailResponse, ISettingsUsersGroupsAddArgs>({
      query(args) {
        return {
          url: RequestAPI.USERS_GROUPS,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'UsersGroupsList', 'UsersGroupsDetail' ],
    }),
    usersGroupsEdit: build.mutation<ISettingsUsersGroupsDetailResponse, ISettingsUsersGroupsEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.USERS_GROUPS_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'UsersGroupsList', 'UsersGroupsDetail' ],
    }),
    usersGroupsRemove: build.mutation<void, ISettingsUsersGroupsRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.USERS_GROUPS_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'UsersGroupsList', 'UsersGroupsDetail' ],
    }),
    usersUsersList: build.query<ISettingsUsersUsersResponse, ISettingsUsersUsersListArgs>({
      query(args) {
        return {
          url: RequestAPI.USERS,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'UsersUsersList' ],
    }),
    usersUsersDetail: build.query<IUserResponse, ISettingsUsersUsersDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.USERS_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'UsersUsersDetail' ],
    }),
    usersUsersAdd: build.mutation<IUserResponse, ISettingsUsersUsersAddArgs>({
      query(args) {
        return {
          url: RequestAPI.USERS,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'UsersUsersList', 'UsersUsersDetail' ],
    }),
    usersUsersEdit: build.mutation<IUserResponse, ISettingsUsersUsersEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.USERS_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'UsersUsersList', 'UsersUsersDetail' ],
    }),
    usersUsersPassword: build.mutation<void, ISettingsUsersUsersEditPasswordArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.USERS_PASSWORD, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'UsersUsersList', 'UsersUsersDetail' ],
    }),
    usersUsersRemove: build.mutation<void, ISettingsUsersUsersRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.USERS_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'UsersUsersList', 'UsersUsersDetail' ],
    }),
    billingContributionsList: build.query<ISettingsBillingContributionsResponse, ISettingsBillingContributionsListArgs>({
      query(args) {
        return {
          url: RequestAPI.BILLING_CONTRIBUTIONS,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'BillingContributionsList' ],
    }),
    billingContributionsDetail: build.query<ISettingsBillingContributionsItemResponse, ISettingsBillingContributionsDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.BILLING_CONTRIBUTIONS_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'BillingContributionsDetail' ],
    }),
    billingContributionsAdd: build.mutation<ISettingsBillingContributionsItemResponse, ISettingsBillingContributionsAddArgs>({
      query(args) {
        return {
          url: RequestAPI.BILLING_CONTRIBUTIONS,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'BillingContributionsList', 'BillingContributionsDetail' ],
    }),
    billingContributionsEdit: build.mutation<ISettingsBillingContributionsItemResponse, ISettingsBillingContributionsEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.BILLING_CONTRIBUTIONS_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'BillingContributionsList', 'BillingContributionsDetail' ],
    }),
    billingContributionsRemove: build.mutation<void, ISettingsBillingContributionsRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.BILLING_CONTRIBUTIONS_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'BillingContributionsList', 'BillingContributionsDetail' ],
    }),
    billingCompanyCategoriesList: build.query<
    ISettingsBillingCompanyCategoriesResponse,
    ISettingsBillingCompanyCategoriesListArgs
    >({
      query(args) {
        return {
          url: RequestAPI.BILLING_COMPANY_CATEGORIES,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'BillingCompanyCategoriesList' ],
    }),
    billingCompanyCategoriesDetail: build.query<
    ISettingsBillingCompanyCategoriesItemResponse,
    ISettingsBillingCompanyCategoriesDetailArgs
    >({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.BILLING_COMPANY_CATEGORIES_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'BillingCompanyCategoriesDetail' ],
    }),
    billingCompanyCategoriesAdd: build.mutation<ISettingsBillingCompanyCategoriesItemResponse, ISettingsBillingCompanyCategoriesAddArgs>({
      query(args) {
        return {
          url: RequestAPI.BILLING_COMPANY_CATEGORIES,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'BillingCompanyCategoriesList', 'BillingCompanyCategoriesDetail' ],
    }),
    billingCompanyCategoriesEdit: build.mutation<ISettingsBillingCompanyCategoriesItemResponse, ISettingsBillingCompanyCategoriesEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.BILLING_COMPANY_CATEGORIES_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'BillingCompanyCategoriesList', 'BillingCompanyCategoriesDetail' ],
    }),
    billingCompanyCategoriesRemove: build.mutation<void, ISettingsBillingCompanyCategoriesRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.BILLING_COMPANY_CATEGORIES_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'BillingCompanyCategoriesList', 'BillingCompanyCategoriesDetail' ],
    }),
    geoCountriesList: build.query<ISettingsGeoCountriesResponse, ISettingsGeoCountriesListArgs>({
      query(args) {
        return {
          url: RequestAPI.COUNTRIES,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'GeoCountriesList' ],
    }),
    geoCountriesListAll: build.query<ISettingsGeoCountriesResponse, void>({
      query() {
        return {
          url: RequestAPI.COUNTRIES,
          params: dataToSnakeCase({ page: 1, pageSize: 1000 }),
        };
      },
      providesTags: [ 'GeoCountriesList' ],
    }),
    geoCountriesDetail: build.query<ISettingsGeoCountriesItemResponse, ISettingsGeoCountriesDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.COUNTRIES_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'GeoCountriesDetail' ],
    }),
    geoCountriesAdd: build.mutation<ISettingsGeoCountriesItemResponse, ISettingsGeoCountriesAddArgs>({
      query(args) {
        return {
          url: RequestAPI.COUNTRIES,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'GeoCountriesList', 'GeoCountriesDetail' ],
    }),
    geoCountriesEdit: build.mutation<ISettingsGeoCountriesItemResponse, ISettingsGeoCountriesEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.COUNTRIES_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'GeoCountriesList', 'GeoCountriesDetail' ],
    }),
    geoCountriesRemove: build.mutation<void, ISettingsGeoCountriesRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.COUNTRIES_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'GeoCountriesList', 'GeoCountriesDetail' ],
    }),
    geoRegionsList: build.query<ISettingsGeoRegionsResponse, ISettingsGeoRegionsListArgs>({
      query(args) {
        return {
          url: RequestAPI.REGIONS,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'GeoRegionsList' ],
    }),
    geoRegionsDetail: build.query<ISettingsGeoRegionsItemResponse, ISettingsGeoRegionsDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.REGIONS_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'GeoRegionsDetail' ],
    }),
    geoRegionsAdd: build.mutation<ISettingsGeoRegionsItemResponse, ISettingsGeoRegionsAddArgs>({
      query(args) {
        return {
          url: RequestAPI.REGIONS,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'GeoRegionsList', 'GeoRegionsDetail' ],
    }),
    geoRegionsEdit: build.mutation<ISettingsGeoRegionsItemResponse, ISettingsGeoRegionsEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.REGIONS_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'GeoRegionsList', 'GeoRegionsDetail' ],
    }),
    geoRegionsRemove: build.mutation<void, ISettingsGeoRegionsRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.REGIONS_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'GeoRegionsList', 'GeoRegionsDetail' ],
    }),
    geoCitiesList: build.query<ISettingsGeoCitiesResponse, ISettingsGeoCitiesListArgs>({
      query(args) {
        return {
          url: RequestAPI.CITIES,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'GeoCitiesList' ],
    }),
    geoCitiesDetail: build.query<ISettingsGeoCitiesItemResponse, ISettingsGeoCitiesDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.CITIES_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'GeoCitiesDetail' ],
    }),
    geoCitiesAdd: build.mutation<ISettingsGeoCitiesItemResponse, ISettingsGeoCitiesAddArgs>({
      query(args) {
        return {
          url: RequestAPI.CITIES,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'GeoCitiesList', 'GeoCitiesDetail' ],
    }),
    geoCitiesEdit: build.mutation<ISettingsGeoCitiesItemResponse, ISettingsGeoCitiesEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.CITIES_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'GeoCitiesList', 'GeoCitiesDetail' ],
    }),
    geoCitiesRemove: build.mutation<void, ISettingsGeoCitiesRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.CITIES_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'GeoCitiesList', 'GeoCitiesDetail' ],
    }),
    geoDistrictsList: build.query<ISettingsGeoDistrictsResponse, ISettingsGeoDistrictsListArgs>({
      query(args) {
        return {
          url: RequestAPI.DISTRICTS,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'GeoDistrictsList' ],
    }),
    geoDistrictsDetail: build.query<ISettingsGeoDistrictsItemResponse, ISettingsGeoDistrictsDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.DISTRICTS_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'GeoDistrictsDetail' ],
    }),
    geoDistrictsAdd: build.mutation<ISettingsGeoDistrictsItemResponse, ISettingsGeoDistrictsAddArgs>({
      query(args) {
        return {
          url: RequestAPI.DISTRICTS,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'GeoDistrictsList', 'GeoDistrictsDetail' ],
    }),
    geoDistrictsEdit: build.mutation<ISettingsGeoDistrictsItemResponse, ISettingsGeoDistrictsEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.DISTRICTS_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'GeoDistrictsList', 'GeoDistrictsDetail' ],
    }),
    geoDistrictsRemove: build.mutation<void, ISettingsGeoDistrictsRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.DISTRICTS_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'GeoDistrictsList', 'GeoDistrictsDetail' ],
    }),
    geoBranchesList: build.query<ISettingsGeoBranchesResponse, ISettingsGeoBranchesListArgs>({
      query(args) {
        return {
          url: RequestAPI.BRANCHES,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'GeoBranchesList' ],
    }),
    geoBranchesListAll: build.query<ISettingsGeoBranchesResponse, void>({
      query() {
        return {
          url: RequestAPI.BRANCHES,
          params: dataToSnakeCase({ page: 1, pageSize: 1000 }),
        };
      },
      providesTags: [ 'GeoBranchesList' ],
    }),
    geoBranchesDetail: build.query<ISettingsGeoBranchesItemResponse, ISettingsGeoBranchesDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.BRANCHES_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'GeoBranchesDetail' ],
    }),
    geoBranchesAdd: build.mutation<ISettingsGeoBranchesItemResponse, ISettingsGeoBranchesAddArgs>({
      query(args) {
        return {
          url: RequestAPI.BRANCHES,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'GeoBranchesList', 'GeoBranchesDetail' ],
    }),
    geoBranchesEdit: build.mutation<ISettingsGeoBranchesItemResponse, ISettingsGeoBranchesEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.BRANCHES_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'GeoBranchesList', 'GeoBranchesDetail' ],
    }),
    geoBranchesRemove: build.mutation<void, ISettingsGeoBranchesRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.BRANCHES_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'GeoBranchesList', 'GeoBranchesDetail' ],
    }),
    systemAgreementDetail: build.query<ISettingsSystemAgreementResponse, void>({
      query() {
        return {
          url: RequestAPI.AGREEMENT,
        };
      },
      providesTags: [ 'SystemAgreementDetail' ],
    }),
    systemAgreementEdit: build.mutation<ISettingsSystemAgreementResponse, ISettingsSystemAgreementEditArgs>({
      query() {
        return {
          url: RequestAPI.AGREEMENT,
          method: 'POST',
        };
      },
      invalidatesTags: [ 'SystemAgreementDetail' ],
    }),
    systemContractsList: build.query<ISettingsSystemContractsResponse, ISettingsSystemContractsListArgs>({
      query(args) {
        return {
          url: RequestAPI.SYSTEM_CONTRACTS,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'SystemContractsList' ],
    }),
    systemContractsDetail: build.query<ISettingsSystemContractsDetailResponse, ISettingsSystemContractsDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.SYSTEM_CONTRACTS_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'SystemContractsDetail' ],
    }),
    systemContractsAdd: build.mutation<ISettingsSystemContractsDetailResponse, ISettingsSystemContractsAddArgs>({
      query(args) {
        return {
          url: RequestAPI.SYSTEM_CONTRACTS,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'SystemContractsList', 'SystemContractsDetail' ],
    }),
    systemContractsEdit: build.mutation<ISettingsSystemContractsDetailResponse, ISettingsSystemContractsEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.SYSTEM_CONTRACTS_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'SystemContractsList', 'SystemContractsDetail' ],
    }),
    systemContractsRemove: build.mutation<void, ISettingsSystemContractsRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.SYSTEM_CONTRACTS_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'SystemContractsList', 'SystemContractsDetail' ],
    }),
    systemOwnershipsList: build.query<ISettingsSystemOwnershipsResponse, ISettingsSystemOwnershipsListArgs>({
      query(args) {
        return {
          url: RequestAPI.OWNERSHIPS,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'SystemOwnershipsList' ],
    }),
    systemOwnershipsDetail: build.query<ISettingsSystemOwnershipsItemResponse, ISettingsSystemOwnershipsDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.OWNERSHIPS_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'SystemOwnershipsDetail' ],
    }),
    systemOwnershipsAdd: build.mutation<ISettingsSystemOwnershipsItemResponse, ISettingsSystemOwnershipsAddArgs>({
      query(args) {
        return {
          url: RequestAPI.OWNERSHIPS,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'SystemOwnershipsList', 'SystemOwnershipsDetail' ],
    }),
    systemOwnershipsEdit: build.mutation<ISettingsSystemOwnershipsItemResponse, ISettingsSystemOwnershipsEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.OWNERSHIPS_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'SystemOwnershipsList', 'SystemOwnershipsDetail' ],
    }),
    systemOwnershipsRemove: build.mutation<void, ISettingsSystemOwnershipsRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.OWNERSHIPS_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'SystemOwnershipsList', 'SystemOwnershipsDetail' ],
    }),
    systemBusinessActivitiesList: build.query<ISettingsSystemBusinessActivitiesResponse, ISettingsSystemBusinessActivitiesListArgs>({
      query(args) {
        return {
          url: RequestAPI.BUSINESS_ACTIVITIES,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'SystemBusinessActivitiesList' ],
    }),
    systemBusinessActivitiesDetail: build.query<
    ISettingsSystemBusinessActivitiesItemResponse,
    ISettingsSystemBusinessActivitiesDetailArgs
    >({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.BUSINESS_ACTIVITIES_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'SystemBusinessActivitiesDetail' ],
    }),
    systemBusinessActivitiesAdd: build.mutation<ISettingsSystemBusinessActivitiesItemResponse, ISettingsSystemBusinessActivitiesAddArgs>({
      query(args) {
        return {
          url: RequestAPI.BUSINESS_ACTIVITIES,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'SystemBusinessActivitiesList', 'SystemBusinessActivitiesDetail' ],
    }),
    systemBusinessActivitiesEdit: build.mutation<ISettingsSystemBusinessActivitiesItemResponse, ISettingsSystemBusinessActivitiesEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.BUSINESS_ACTIVITIES_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'SystemBusinessActivitiesList', 'SystemBusinessActivitiesDetail' ],
    }),
    systemBusinessActivitiesRemove: build.mutation<void, ISettingsSystemBusinessActivitiesRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.BUSINESS_ACTIVITIES_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'SystemBusinessActivitiesList', 'SystemBusinessActivitiesDetail' ],
    }),
    systemEconomicActivitiesList: build.query<ISettingsSystemEconomicActivitiesResponse, ISettingsSystemEconomicActivitiesListArgs>({
      query(args) {
        return {
          url: RequestAPI.ECONOMIC_ACTIVITIES,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'SystemEconomicActivitiesList' ],
    }),
    systemEconomicActivitiesDetail: build.query<
    ISettingsSystemEconomicActivitiesItemResponse,
    ISettingsSystemEconomicActivitiesDetailArgs
    >({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.ECONOMIC_ACTIVITIES_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'SystemEconomicActivitiesDetail' ],
    }),
    systemEconomicActivitiesAdd: build.mutation<ISettingsSystemEconomicActivitiesItemResponse, ISettingsSystemEconomicActivitiesAddArgs>({
      query(args) {
        return {
          url: RequestAPI.ECONOMIC_ACTIVITIES,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'SystemEconomicActivitiesList', 'SystemEconomicActivitiesDetail' ],
    }),
    systemEconomicActivitiesEdit: build.mutation<ISettingsSystemEconomicActivitiesItemResponse, ISettingsSystemEconomicActivitiesEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.ECONOMIC_ACTIVITIES_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'SystemEconomicActivitiesList', 'SystemEconomicActivitiesDetail' ],
    }),
    systemEconomicActivitiesRemove: build.mutation<void, ISettingsSystemEconomicActivitiesRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.ECONOMIC_ACTIVITIES_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'SystemEconomicActivitiesList', 'SystemEconomicActivitiesDetail' ],
    }),
    systemProductTypesList: build.query<ISettingsSystemProductTypesResponse, ISettingsSystemProductTypesListArgs>({
      query(args) {
        return {
          url: RequestAPI.PRODUCT_TYPES,
          params: dataToSnakeCase(args),
        };
      },
    }),
    systemProductTypesUploadExcel: build.mutation<void, ISettingsSystemProductTypesUploadExcelArgs>({
      query(data) {
        return {
          url: RequestAPI.PRODUCT_TYPES,
          method: 'POST',
          data,
        };
      },
    }),
    systemBanksList: build.query<ISettingsSystemBanksResponse, ISettingsSystemBanksListArgs>({
      query(args) {
        return {
          url: RequestAPI.BANKS,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'SystemBanksList' ],
    }),
    systemBanksDetail: build.query<ISettingsSystemBanksItemResponse, ISettingsSystemBanksDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.BANKS_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'SystemBanksDetail' ],
    }),
    systemBanksAdd: build.mutation<ISettingsSystemBanksItemResponse, ISettingsSystemBanksAddArgs>({
      query(args) {
        return {
          url: RequestAPI.BANKS,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'SystemBanksList', 'SystemBanksDetail' ],
    }),
    systemBanksEdit: build.mutation<ISettingsSystemBanksItemResponse, ISettingsSystemBanksEditArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.BANKS_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'SystemBanksList', 'SystemBanksDetail' ],
    }),
    systemBanksRemove: build.mutation<void, ISettingsSystemBanksRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.BANKS_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'SystemBanksList', 'SystemBanksDetail' ],
    }),
  }),
});

export {
  settingsApi,
};
