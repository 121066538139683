import { isEmpty, isNil } from 'ramda';
import { IPermissionsItemResponse } from 'types/response';
import { ISelectOption } from 'components/common/fields/select/SelectTypes';
import { getInitOption } from 'helpers/select';
import Alt from 'helpers/alt';

enum PermissionsCode {
  EXPORT_CREATE = 'create_export',
  STATISTICS_COMPANIES_BY_STATUS = 'statistic_company_status_user',
  STATISTICS_FINANCES_INCOME = 'finance_statistic_user',
  STATISTICS_NEW_COMPANIES_BY_YEARS = 'statistic_company_year_user',
  STATISTICS_COMPANIES_BY_BUSINESS_TYPES = 'statistic_company_category_user',
}
enum PermissionsCompare {
  EVERY = 'every',
  SOME = 'some',
}
const permissionsCompareAlt = PermissionsCompare.EVERY;

interface IPermissionsEqArgs {
  current: IPermissionsItemResponse['codename'][]
  profile: IPermissionsItemResponse['id'][] | undefined
  all: IPermissionsItemResponse[] | undefined
  compareBy?: PermissionsCompare
}
interface IHasPermissionsArgs extends IPermissionsEqArgs {
  isSuperuser: boolean | undefined
}
interface IGetPermissionsByIds {
  permissions: IPermissionsItemResponse[]
  ids: IPermissionsItemResponse['id'][]
}
interface IGetSelectOptionsByPermissionsIds extends IGetPermissionsByIds {}

const permissionsEq = (args: IPermissionsEqArgs): boolean => {
  const {
    current: currentCodenames = [],
    profile: profileIds = [],
    all: allPermissions = [],
    compareBy = permissionsCompareAlt,
  } = args;

  const compareIsByEvery = compareBy === PermissionsCompare.EVERY;

  const profileCodenames = allPermissions
    ?.filter((item) => profileIds
      ?.find((id) => item?.id === id))
    ?.map((item) => item.codename);
  const allCodenames = allPermissions?.map((item) => item.codename);

  // Current не задан, проверка не нужна
  if (isNil(currentCodenames) || isEmpty(currentCodenames)) return true;
  if (isNil(profileCodenames) || isEmpty(profileCodenames)) return false;
  if (isNil(allCodenames) || isEmpty(allCodenames)) return false;
  if (!currentCodenames.every((item) => allCodenames.includes(item))) return false;
  // Проверяем на наличие всех доступов из массива
  if (compareIsByEvery) return currentCodenames.every((item) => profileCodenames.includes(item));
  // Проверяем на наличие хотя бы одного доступа из массива
  return currentCodenames.some((item) => profileCodenames.includes(item));
};

const hasPermissions = (args: IHasPermissionsArgs) => {
  const { isSuperuser, ...rest } = args;

  return isSuperuser || permissionsEq(rest);
};
const getPermissionsByIds = (args: IGetPermissionsByIds): IPermissionsItemResponse[] => {
  const { permissions, ids } = args;

  const currentPermissions = ids?.map((i) => permissions?.find((f) => f?.id === i) || null);
  return currentPermissions?.filter((f) => !isNil(f)) as IPermissionsItemResponse[];
};
const getSelectOptionsByPermissionsIds = (args: IGetSelectOptionsByPermissionsIds): ISelectOption[] => {
  const { permissions, ids } = args;

  const currentPermissions = getPermissionsByIds({ permissions, ids });

  return currentPermissions
    ?.map((i) => getInitOption(i?.name || Alt.OPTION, i?.id))
    ?.filter((f) => !isNil(f)) as ISelectOption[];
};

export type {
  IPermissionsEqArgs,
};

export {
  PermissionsCode,
  PermissionsCompare,
  permissionsCompareAlt,
  permissionsEq,
  hasPermissions,
  getPermissionsByIds,
  getSelectOptionsByPermissionsIds,
};
