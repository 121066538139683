import { createApi } from '@reduxjs/toolkit/dist/query/react';
import StateName from 'helpers/stateNames';
import { RequestAPI } from 'helpers/requests';
import { axiosBaseQuery } from 'helpers/axios';
import { dataToSnakeCase } from 'helpers/register';
import { vsprintf } from 'sprintf-js';
import {
  ICompaniesListResponse,
  ICompaniesDetailResponse,
  ICompaniesStatusesCountResponse,
  ICompaniesDocsResponse,
  ICompaniesHistoryResponse,
  ICompaniesHistoryItemResponse,
} from 'types/response';
import {
  ICompaniesListArgs,
  ICompaniesStatusesCountArgs,
  ICompaniesDetailArgs,
  ICompaniesAddArgs,
  ICompaniesEditArgs,
  ICompaniesExcludeArgs,
  ICompaniesActivateArgs,
  ICompaniesRemoveArgs,
  ICompaniesRestoreArgs,
  ICompaniesMergeArgs,
  ICompaniesSearchArgs,
  ICompaniesDocsArgs,
  ICompaniesDocsRemoveAllArgs,
  ICompaniesHistoryArgs,
  ICompaniesAddHistoryArgs,
  ICompaniesRemoveHistoryArgs,
} from 'components/services/companies/companiesTypes';

const companiesApi = createApi({
  reducerPath: StateName.COMPANIES_API,
  baseQuery: axiosBaseQuery(),
  tagTypes: [ 'CompaniesList', 'CompaniesDetail', 'CompaniesHistoryList', 'CompaniesDocsList' ],
  endpoints: (build) => ({
    list: build.query<ICompaniesListResponse, ICompaniesListArgs>({
      query(args) {
        return {
          url: RequestAPI.COMPANIES,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'CompaniesList' ],
    }),
    detail: build.query<ICompaniesDetailResponse, ICompaniesDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.COMPANIES_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'CompaniesDetail' ],
    }),
    statusesCount: build.query<ICompaniesStatusesCountResponse, ICompaniesStatusesCountArgs | void>({
      query(args) {
        return {
          url: RequestAPI.COMPANIES_STATUSES_COUNT,
          params: dataToSnakeCase(args),
        };
      },
    }),
    add: build.mutation<ICompaniesDetailResponse, ICompaniesAddArgs>({
      query(args) {
        return {
          url: RequestAPI.COMPANIES,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'CompaniesList', 'CompaniesDetail' ],
    }),
    edit: build.mutation<ICompaniesDetailResponse, ICompaniesEditArgs>({
      query({ id, ...params }) {
        return {
          url: vsprintf(RequestAPI.COMPANIES_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(params),
        };
      },
      invalidatesTags: [ 'CompaniesList', 'CompaniesDetail' ],
    }),
    exclude: build.mutation<void, ICompaniesExcludeArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.COMPANIES_EXCLUDE, [ id ]),
          method: 'POST',
        };
      },
      invalidatesTags: [ 'CompaniesList', 'CompaniesDetail' ],
    }),
    activate: build.mutation<void, ICompaniesActivateArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.COMPANIES_ACTIVATE, [ id ]),
          method: 'POST',
        };
      },
      invalidatesTags: [ 'CompaniesList', 'CompaniesDetail' ],
    }),
    remove: build.mutation<void, ICompaniesRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.COMPANIES_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'CompaniesList', 'CompaniesDetail' ],
    }),
    restore: build.mutation<void, ICompaniesRestoreArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.COMPANIES_RESTORE, [ id ]),
          method: 'POST',
        };
      },
      invalidatesTags: [ 'CompaniesList', 'CompaniesDetail' ],
    }),
    merge: build.mutation<void, ICompaniesMergeArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.COMPANIES_MERGE, [ id ]),
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'CompaniesList', 'CompaniesDetail' ],
    }),
    search: build.query<ICompaniesListResponse, ICompaniesSearchArgs>({
      query(args) {
        return {
          url: RequestAPI.COMPANIES,
          params: dataToSnakeCase({ ...args, pageSize: 5 }),
        };
      },
    }),
    docs: build.query<ICompaniesDocsResponse, ICompaniesDocsArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.COMPANIES_DOCS, [ id ]),
        };
      },
      providesTags: [ 'CompaniesDocsList' ],
    }),
    docsRemoveAll: build.mutation<void, ICompaniesDocsRemoveAllArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.COMPANIES_DOCS_REMOVE_ALL, [ id ]),
          method: 'POST',
        };
      },
      invalidatesTags: [ 'CompaniesDocsList' ],
    }),
    history: build.query<ICompaniesHistoryResponse, ICompaniesHistoryArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.COMPANIES_HISTORY, [ id ]),
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'CompaniesHistoryList' ],
    }),
    addHistory: build.mutation<ICompaniesHistoryItemResponse, ICompaniesAddHistoryArgs>({
      query({ id, ...args }) {
        return {
          url: vsprintf(RequestAPI.COMPANIES_HISTORY_CREATE, [ id ]),
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'CompaniesHistoryList' ],
    }),
    removeHistory: build.mutation<void, ICompaniesRemoveHistoryArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.HISTORY_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'CompaniesHistoryList' ],
    }),
  }),
});

export { companiesApi };
