import React, { useMemo, useState } from 'react';
import SidebarMenuItem from 'components/features/sidebar/menu/item/SidebarMenuItem';
import SidebarProfile from 'components/features/sidebar/profile/SidebarProfile';
import RoutePath from 'helpers/routePath';
import { CompanyStatusAlias, companyStatuses, getCompaniesCountByStatusAlias } from 'helpers/companies';
import { contractStatuses } from 'helpers/contracts';
import { companiesApi } from 'components/services/companies/companiesService';
import { vsprintf } from 'sprintf-js';
import { ReactComponent as LogoFullIcon } from 'media/images/logo-full.svg';
import { ReactComponent as LogoShortIcon } from 'media/images/logo-short.svg';
import { ReactComponent as CompaniesIcon } from 'media/icons/menu-companies.svg';
import { ReactComponent as ContractsIcon } from 'media/icons/menu-contracts.svg';
import { ReactComponent as ProductsIcon } from 'media/icons/menu-products.svg';
// import { ReactComponent as StatsIcon } from 'media/icons/menu-stats.svg';
import { ReactComponent as SettingsIcon } from 'media/icons/menu-settings.svg';
import useSelector from 'hooks/redux/useSelector';
import StateName from 'helpers/stateNames';
import SidebarTheme from 'components/features/sidebar/theme/SidebarTheme';
import { StorageName, storage } from 'helpers/storage';
import useQuery from 'hooks/router/useQuery';
import SearchQuery from 'helpers/searchQuery';
import { productStatuses } from 'helpers/products';
// import { PermissionsCode, PermissionsCompare } from 'helpers/permissions';
import Permissions from 'components/common/permissions/Permissions';
import { ISidebarMenu, ISidebarProps, SidebarType } from './SidebarTypes';
import {
  SidebarHeader,
  SidebarHeaderLogo,
  SidebarMenuBottom,
  SidebarMenuTop,
  SidebarWrap,
} from './SidebarStyles';

const renderMenuList = (arr: ISidebarMenu, isShort: boolean) => arr.map((item) => (
  <Permissions
    current={item?.permissions?.current}
    compareBy={item?.permissions?.compareBy}
    key={item.path}
  >
    <SidebarMenuItem
      item={item}
      isShort={isShort}
    />
  </Permissions>
));

function Sidebar(props: ISidebarProps) {
  const {
    type = SidebarType.FULL,
  } = props;

  const isCompressedInit = storage(StorageName.SIDEBAR_COMPRESSED).getValue() === true;
  const [ isCompressed, setIsCompressed ] = useState<boolean>(isCompressedInit);

  const authState = useSelector((state) => state[StateName.AUTH]);
  const isAuth = authState.verify;

  const { getQueryByKey, getQueryBoolByKey } = useQuery();

  const searchQueryName = SearchQuery.SEARCH;
  const regionQueryName = SearchQuery.REGION;
  const branchesQueryName = SearchQuery.BRANCHES;
  const ownershipsQueryName = SearchQuery.OWNERSHIPS;
  const createdYearQueryName = SearchQuery.CREATED_YEAR;
  const createdMonthQueryName = SearchQuery.CREATED_MONTH;
  const encodingQueryName = SearchQuery.ENCODING;

  const searchQuery = getQueryByKey(searchQueryName);
  const regionQuery = getQueryByKey(regionQueryName);
  const branchesQuery = getQueryByKey(branchesQueryName);
  const ownershipsQuery = getQueryByKey(ownershipsQueryName);
  const createdYearQuery = getQueryByKey(createdYearQueryName);
  const createdMonthQuery = getQueryByKey(createdMonthQueryName);
  const encodingQuery = getQueryBoolByKey(encodingQueryName);

  const { useStatusesCountQuery } = companiesApi;
  const { data: companiesStatusesCounts } = useStatusesCountQuery({
    search: searchQuery,
    region: regionQuery,
    branches: branchesQuery,
    ownership: ownershipsQuery,
    createdDateYear: createdYearQuery,
    createdDateMonth: createdMonthQuery,
    encoding: encodingQuery,
  }, { skip: !isAuth });

  const menuTop: ISidebarMenu = useMemo(
    () => (
      [
        {
          label: 'Компании',
          path: RoutePath.COMPANIES,
          icon: CompaniesIcon,
          count: getCompaniesCountByStatusAlias(companiesStatusesCounts, CompanyStatusAlias.ALL),
          children: companyStatuses
            .map(({ label: { multi }, alias }) => ({
              label: multi,
              path: vsprintf(RoutePath.COMPANIES_BY_STATUS, [ alias ]),
              count: getCompaniesCountByStatusAlias(companiesStatusesCounts, alias),
            })),
        },
        {
          label: 'Договора',
          path: RoutePath.CONTRACTS,
          icon: ContractsIcon,
          children: contractStatuses
            .map(({ label: { multi }, alias }) => ({
              label: multi,
              path: vsprintf(RoutePath.CONTRACTS_BY_STATUS, [ alias ]),
            })),
        },
        {
          label: 'Продукты',
          path: RoutePath.PRODUCTS,
          icon: ProductsIcon,
          children: productStatuses
            .map(({ label: { multi }, alias }) => ({
              label: multi,
              path: vsprintf(RoutePath.PRODUCTS_BY_STATUS, [ alias ]),
            })),
        },
        // TODO Rou 21/07/24 Раскомментировать после проверки менеджером?!
        // {
        //   label: 'Статистика',
        //   path: RoutePath.STATISTICS,
        //   icon: StatsIcon,
        //   permissions: {
        //     current: [
        //       PermissionsCode.STATISTICS_FINANCES_INCOME,
        //       PermissionsCode.STATISTICS_COMPANIES_BY_STATUS,
        //       PermissionsCode.STATISTICS_NEW_COMPANIES_BY_YEARS,
        //       PermissionsCode.STATISTICS_COMPANIES_BY_BUSINESS_TYPES,
        //     ],
        //     compareBy: PermissionsCompare.SOME,
        //   },
        // },
      ]
    ),
    [
      companiesStatusesCounts,
      companyStatuses,
      contractStatuses,
    ],
  );

  const menuBottom: ISidebarMenu = [
    {
      label: 'Настройки',
      path: RoutePath.SETTINGS,
      icon: SettingsIcon,
    },
  ];

  const isFull = type === SidebarType.FULL;
  const isFullCompressed = isFull && isCompressed;
  const isShort = isFullCompressed || type === SidebarType.COMPACT;
  const isHide = type === SidebarType.HIDE;

  const handleToggleCompressed = () => {
    const is = !isCompressed;
    storage(StorageName.SIDEBAR_COMPRESSED).setValue(is);
    setIsCompressed(is);
  };

  return (
    <SidebarWrap
      isShort={isShort}
      isHide={isHide}
    >
      <SidebarHeader>
        <SidebarHeaderLogo
          to={RoutePath.ROOT}
          state={{ isShort }}
        >
          {isShort ? <LogoShortIcon /> : <LogoFullIcon />}
        </SidebarHeaderLogo>
      </SidebarHeader>
      <SidebarMenuTop isShort={isShort}>
        {renderMenuList(menuTop, isShort)}
      </SidebarMenuTop>
      <SidebarMenuBottom isShort={isShort}>
        {renderMenuList(menuBottom, isShort)}
      </SidebarMenuBottom>
      <SidebarTheme
        isShort={isShort}
        isCompressed={isCompressed}
        handleToggleCompressed={handleToggleCompressed}
      />
      <SidebarProfile isShort={isShort} />
    </SidebarWrap>
  );
}

export default Sidebar;
