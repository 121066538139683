import { createApi } from '@reduxjs/toolkit/dist/query/react';
import StateName from 'helpers/stateNames';
import { RequestAPI } from 'helpers/requests';
import { axiosBaseQuery } from 'helpers/axios';
import { dataToSnakeCase } from 'helpers/register';
import { vsprintf } from 'sprintf-js';
import {
  IProductsListArgs,
  IProductsListByCompanyArgs,
  IProductsStatusesUpdateArgs,
  IProductsAddArgs,
  IProductsEditArgs,
  IProductsRemoveArgs,
  IProductsCompaniesArgs,
  IProductsSearchArgs,
  IProductsDetailArgs,
  IProductsUploadExcelArgs, IProductsPackagesUploadExcelArgs,
} from 'components/services/products/productsTypes';
import {
  IProductsListResponse,
  IProductsDetailResponse,
  IProductsCompaniesResponse,
} from 'types/response';

const productsApi = createApi({
  reducerPath: StateName.PRODUCTS_API,
  baseQuery: axiosBaseQuery(),
  tagTypes: [ 'ProductsList', 'ProductsDetail' ],
  endpoints: (build) => ({
    list: build.query<IProductsListResponse, IProductsListArgs>({
      query(args) {
        return {
          url: RequestAPI.PRODUCTS,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'ProductsList' ],
    }),
    listByCompany: build.query<IProductsListResponse, IProductsListByCompanyArgs>({
      query({ companyId, ...params }) {
        return {
          url: RequestAPI.PRODUCTS,
          params: { company: companyId, ...dataToSnakeCase(params) },
        };
      },
      providesTags: [ 'ProductsList' ],
    }),
    detail: build.query<IProductsDetailResponse, IProductsDetailArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.PRODUCTS_DETAIL, [ id ]),
        };
      },
      providesTags: [ 'ProductsDetail' ],
    }),
    companies: build.query<IProductsCompaniesResponse, IProductsCompaniesArgs>({
      query(args) {
        return {
          url: RequestAPI.PRODUCTS_COMPANIES,
          params: dataToSnakeCase(args),
        };
      },
      providesTags: [ 'ProductsList' ],
    }),
    statusesUpdate: build.mutation<void, IProductsStatusesUpdateArgs>({
      query(args) {
        return {
          url: RequestAPI.PRODUCTS_STATUSES,
          method: 'PUT',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'ProductsList', 'ProductsDetail' ],
    }),
    add: build.mutation<IProductsDetailResponse, IProductsAddArgs>({
      query(args) {
        return {
          url: RequestAPI.PRODUCTS,
          method: 'POST',
          data: dataToSnakeCase(args),
        };
      },
      invalidatesTags: [ 'ProductsList', 'ProductsDetail' ],
    }),
    edit: build.mutation<IProductsDetailResponse, IProductsEditArgs>({
      query({ id, ...params }) {
        return {
          url: vsprintf(RequestAPI.PRODUCTS_DETAIL, [ id ]),
          method: 'PUT',
          data: dataToSnakeCase(params),
        };
      },
      invalidatesTags: [ 'ProductsList', 'ProductsDetail' ],
    }),
    remove: build.mutation<void, IProductsRemoveArgs>({
      query({ id }) {
        return {
          url: vsprintf(RequestAPI.PRODUCTS_DETAIL, [ id ]),
          method: 'DELETE',
        };
      },
      invalidatesTags: [ 'ProductsList', 'ProductsDetail' ],
    }),
    search: build.query<IProductsListResponse, IProductsSearchArgs>({
      query(args) {
        return {
          url: RequestAPI.PRODUCTS,
          params: dataToSnakeCase({ ...args, pageSize: 5 }),
        };
      },
      providesTags: [ 'ProductsList' ],
    }),
    uploadExcel: build.mutation<void, IProductsUploadExcelArgs>({
      query(data) {
        return {
          url: RequestAPI.PRODUCTS_EXCEL,
          method: 'POST',
          data,
        };
      },
      invalidatesTags: [ 'ProductsList', 'ProductsDetail' ],
    }),
    packagesUploadExcel: build.mutation<void, IProductsPackagesUploadExcelArgs>({
      query(data) {
        return {
          url: RequestAPI.PRODUCTS_PACKAGES_EXCEL,
          method: 'POST',
          data,
        };
      },
      invalidatesTags: [ 'ProductsList', 'ProductsDetail' ],
    }),
  }),
});

export {
  productsApi,
};
