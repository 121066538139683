import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from 'helpers/routes';
import Fallback from 'components/common/fallback/Fallback';
import MainLayout from 'components/layouts/main/MainLayout';
import SearchGlobal from 'components/features/search/global/SearchGlobal';
import Modal from 'components/common/modal/Modal';
import Tooltip from 'components/common/tooltip/Tooltip';
import Toasts from 'components/common/toasts/Toasts';
import Export from 'components/features/export/Export';
import { profileApi } from 'components/services/profile/profileService';
import Permissions from 'components/common/permissions/Permissions';
import useSelector from 'hooks/redux/useSelector';
import StateName from 'helpers/stateNames';
import { PermissionsCode } from 'helpers/permissions';

function App() {
  const routesEl = useRoutes(routes);

  const authState = useSelector((state) => state[StateName.AUTH]);
  const isAuth = authState.verify;

  const { useDetailQuery: useProfileQuery, usePermissionsQuery } = profileApi;
  const { isLoading: profileIsLoading, isSuccess: profileIsSuccess } = useProfileQuery(undefined, { skip: !isAuth });
  const { isLoading: permissionsIsLoading } = usePermissionsQuery(undefined, { skip: !profileIsSuccess });
  const isLoading = profileIsLoading || permissionsIsLoading;

  if (isLoading) return <Fallback />;
  return (
    <MainLayout>
      <Suspense fallback={<Fallback />}>
        {routesEl}
        <SearchGlobal />
        <Permissions current={[ PermissionsCode.EXPORT_CREATE ]}>
          <Export />
        </Permissions>
        <Modal />
        <Tooltip />
        <Toasts />
      </Suspense>
    </MainLayout>
  );
}

export default App;
